import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import styles from './boxed-screen.scss?inline';
import ApiService from './../services/APIService';
import Spinner from './../services/Spinner';

var api = new ApiService();
@customElement('login-app')
export class AppLogin extends LitElement {
  static styles = unsafeCSS(styles);
  @state() IsLoading: boolean = false;
  @state() error: string = '';
  @state() forgetPassword: boolean = false;

  constructor() {
    super();
    if (window.localStorage.getItem('pg_token')) {
      var api = new ApiService();
      api.ValidateLogin().then((isValid) => {
        if (isValid) {
          window.location.href = '/overview';
        }
      });
    }
  }

  private DoLogin() {
    if (this.forgetPassword) {
      // TODO: Do Something
      this.forgetPassword = false;
    } else {
      this.IsLoading = true;
      this.error = '';
      var email = this.shadowRoot?.querySelector(
        '#email'
      ) as HTMLInputElement | null;
      var password = this.shadowRoot?.querySelector(
        '#password'
      ) as HTMLInputElement | null;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email?.value,
          password: password?.value,
        }),
      };
      fetch(api.GetLoginApiUrl(), requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          } else {
            throw new Error('Not successfull');
          }
        })
        .then((data) => {
          window.localStorage.setItem('pg_token', data);
          api.GetSubscription().then(
            (subscriptionId) => {
              window.location.href = subscriptionId
                ? '/overview'
                : '/subscription';
            },
            (error) => {
              this.IsLoading = false;
              this.error = error;
            }
          );
        })
        .catch((error) => {
          this.IsLoading = false;
          this.error = error;
        });
    }
  }

  keydown(e: any) {
    if (e.keyCode === 13) {
      this.DoLogin();
    }
  }

  render() {
    return html`
      <main>
        <div class="wrapper">
          <img
            src="/images/promoguy-logo.png"
            class="logo-mobile"
            alt="Promoguy Login"
          />
          <div class="box">
            <div class="promologin">
              <img
                src="/images/promoguy-logo.png"
                class="logo"
                alt="Promoguy Login"
              />
              <div class="headline">We do the math, you make the cash.</div>
              <p>
                Welcome to PromoGuy Plus! Your go-to app and community for
                smarter +EV Betting in the US. Sign up and get access to
                exclusive data-driven bets daily, as soon as we find them!
              </p>
              <p class="bold">
                We have tracked on average 30% ROI on our+EV bets in the past 12
                months.
              </p>
              <p>
                Whether you’re a beginner or a pro, get real-time +EV bets
                straight to your phone and desktop!
              </p>
            </div>
            <div class="login">
              <div class="headline">Welcome back</div>
              <div class="form">
                <form>
                  <input
                    type="text"
                    placeholder="E-Mail"
                    id="email"
                    @keydown=${this.keydown}
                  />
                  ${!this.forgetPassword
                    ? html` <input
                        type="password"
                        placeholder="Password"
                        id="password"
                        @keydown=${this.keydown}
                      />`
                    : ''}
                  <p>
                    <a
                      href="#"
                      @click=${() => {
                        this.forgetPassword = true;
                        return false;
                      }}
                      >Forgot password?</a
                    >
                  </p>
                  <div
                    @click="${this.IsLoading ? () => {} : this.DoLogin}"
                    class="btn ${this.IsLoading ? 'btn-disabled' : ''}"
                  >
                    ${this.IsLoading
                      ? Spinner.renderSpinner()
                      : this.forgetPassword
                      ? 'REQUEST PASSWORD'
                      : 'SIGN IN'}
                  </div>
                  ${this.error != ''
                    ? html`<p class="error">
                        Login not successful. Please try again.
                      </p>`
                    : ''}
                </form>
                <p class="text-light center">or continue with</p>
                <div
                  class="social-buttons"
                  @click=${() => {
                    window.location.href = '/auth/registerdiscord';
                  }}
                >
                  <div class="social-btn discord">Discord</div>
                </div>

                <p class="text-light center">
                  Not a member? <a href="/register">Sign Up</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    `;
  }
}
