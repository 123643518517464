export default class ApiService {
  constructor() {}
  public GetApiService() {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('127.0.0.1:')
    ) {
      return 'http://localhost:4000/';
    } else {
      return '';
    }
  }

  public async ChangeValid(id: string, valid: number) {
    const response = await fetch(this.GetApiService() + '_api/changevalid', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        valid: valid,
        id: id,
      }),
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async UpdateFilter(filter: string) {
    const response = await fetch(this.GetApiService() + '_api/postfilter', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: filter,
      }),
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async GetFilter() {
    try {
      const response = await fetch(this.GetApiService() + '_api/filter', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
      }).catch(() => {
        return null;
      });

      if (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return {};
        }
      }
    } catch {
      return {};
    }
  }

  public async PostMe(
    firstname: string,
    lastname: string,
    username: string,
    email: string,
    password: string
  ) {
    const response = await fetch(this.GetApiService() + '_api/postme', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        password: password,
      }),
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async Me() {
    const response = await fetch(this.GetApiService() + '_api/me', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return {};
    }
  }

  public async PostSocialAction(id: string, type: number) {
    const response = await fetch(
      this.GetApiService() + '_api/updatesocialactions',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          betid: id,
          type: type,
        }),
      }
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public async GetSocialActions(id: string) {
    const response = await fetch(
      this.GetApiService() + '_api/getsocialactions/' + id,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   *  Post a comment
   * @param id
   * @param text
   * @returns Boolean
   */
  public async PostComment(id: string, text: string) {
    const response = await fetch(this.GetApiService() + '_api/postcomment', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        betid: id,
        text: text,
      }),
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  /**
   * Get all comments for a bet - id
   * @param id bet id
   * @returns comments json-array
   */
  public async GetComments(id: string) {
    const response = await fetch(this.GetApiService() + '_api/comments/' + id, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   * Gets all Contributors
   * @returns json-array
   */
  public async GetContributors() {
    const response = await fetch(this.GetApiService() + '_api/contributors', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   * Get all Bets
   * @returns json-array
   */
  public async GetBets() {
    const response = await fetch(this.GetApiService() + '_api/bets', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   * Get my Bets
   * @returns json-array
   */
  public async GetMyBets() {
    const response = await fetch(this.GetApiService() + '_api/mybets', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   * Gets a bet
   * @returns json-array
   */
  public async GetBet(id: string) {
    const response = await fetch(this.GetApiService() + `_api/bets/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  /**
   * Gets all GetSportsbooks
   * @returns json-array
   */
  public async GetSportsbooks() {
    const response = await fetch(this.GetApiService() + '_api/sportsbooks', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  public async SetOneSignalData(userId: string, subscriptionId: string) {
    const response = await fetch(
      this.GetApiService() + '_api/setonesignaldata',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userid: userId,
          subscriptionid: subscriptionId,
        }),
      }
    );

    if (response.status === 200) {
      return response.json();
    }
  }

  public async GetOneSignalData() {
    const response = await fetch(
      this.GetApiService() + '_api/getonesignaldata',
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.json();
    }
  }

  public async GetSubscription() {
    const response = await fetch(
      this.GetApiService() + '_api/hassubscription',
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return [];
    }
  }

  public async AddUserSubscription(sessionId: string) {
    const response = await fetch(
      this.GetApiService() + '_api/registersubscription',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeSessionId: sessionId,
        }),
      }
    );
    if (response.status !== 200) {
      throw new Error('Not successfull');
    }
  }

  public async GetId() {
    const response = await fetch(this.GetApiService() + '_api/getid', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return {};
    }
  }

  /**
   * Validates if the login is valid for protected areas
   * @returns boolean
   */
  public async ValidateLogin() {
    if (!window.localStorage.getItem('pg_token')) {
      return false;
    }
    const response = await fetch(this.GetApiService() + '_api/validate', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('pg_token')}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  private isDev() {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('127.0.0.1:')
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the api url for login endpoint
   * @returns string
   */
  public GetLoginApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/login';
    } else {
      return '/_api/login';
    }
  }

  /** Returns the api url for register endpoint
   * @returns string
   */
  public GetRegisterStripeDataApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/registergetstripedata';
    } else {
      return '/_api/registergetstripedata';
    }
  }

  /** Returns the api url for register endpoint
   * @returns string
   */
  public GetRegisterCheckoutSessionApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/registercheckoutsession';
    } else {
      return '/_api/registercheckoutsession';
    }
  }

  /** Returns the api url for register endpoint
   * @returns string
   */
  public GetSubscriptionCheckoutSessionApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/subscriptioncheckoutsession';
    } else {
      return '/_api/subscriptioncheckoutsession';
    }
  }

  /** Returns the api url for register endpoint
   * @returns string
   */
  public GetValidateRegisterApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/validateregister';
    } else {
      return '/_api/validateregister';
    }
  }

  /** Returns the api url for register endpoint
   * @returns string
   */
  public GetRegisterApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/register';
    } else {
      return '/_api/register';
    }
  }

  /**
   * Returns the api url for addbet endpoint
   * @returns string
   */
  public GetAddBetApiUrl() {
    if (this.isDev()) {
      return 'http://localhost:4000/_api/addbet';
    } else {
      return '/_api/addbet';
    }
  }

  /**
   * Returns the api url for editbet endpoint
   * @returns string
   */
  public GetEditBetApiUrl(id: string) {
    if (this.isDev()) {
      return `http://localhost:4000/_api/editbet/${id}`;
    } else {
      return `/_api/addbet/${id}`;
    }
  }
}
