import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import styles from './boxed-screen.scss?inline';
import Spinner from './../services/Spinner';
import ApiService from '../services/APIService';
import UrlService from '../services/UrlService';
import DataStorageService from '../services/DataStorageService';

@customElement('register-app')
export class AppRegister extends LitElement {
  static styles = unsafeCSS(styles);

  @state() IsLoading: boolean = true;
  @state() Register: boolean = false;
  @state() Lastname: string = ``;
  @state() Firstname: string = ``;
  @state() Password: string = ``;
  @state() Email: string = ``;
  @state() ShowStripe: boolean = false;
  @state() showError: boolean = false;
  @state() showEmailTaken: boolean = false;
  private stripeSessionId: string = '';

  connectedCallback() {
    super.connectedCallback();
    let sessionId = UrlService.getUrlParam('session_id');
    if (!sessionId) {
      DataStorageService.clearRegisterData();
    } else {
      this.stripeSessionId = sessionId;
      let registerData = DataStorageService.getRegisterData();
      if (registerData) {
        this.Firstname = registerData.firstname;
        this.Lastname = registerData.lastname;
        this.Email = registerData.email;
        this.Password = registerData.password;
        if (this.Firstname && this.Lastname && this.Email && this.Password) {
          this.CallRegisterAPI();
        }
      }
    }
  }

  GetClientSecret = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      var api = new ApiService();
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      fetch(api.GetRegisterCheckoutSessionApiUrl(), requestOptions)
        .then(
          (response) => response.json(),
          (err) => reject(err)
        )
        .then(
          (data) => {
            resolve(data.clientSecret);
          },
          (err) => reject(err)
        );
    });
  };

  CallRegisterCheckoutSessionAPI() {
    DataStorageService.saveRegisterData({
      firstname: this.Firstname,
      lastname: this.Lastname,
      email: this.Email,
      password: this.Password,
    });

    var api = new ApiService();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(api.GetRegisterStripeDataApiUrl(), requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const stripe = Stripe(data.publicKey);
        stripe
          .initEmbeddedCheckout({
            fetchClientSecret: this.GetClientSecret,
          })
          .then(
            (checkout: any) => {
              checkout.mount(this.shadowRoot.getElementById('checkout'));
            },
            (err) => {}
          );
      });
  }

  CallRegisterAPI() {
    this.Register = true;

    var api = new ApiService();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.Email,
        firstname: this.Firstname,
        lastname: this.Lastname,
        password: this.Password,
        stripeSessionId: this.stripeSessionId,
      }),
    };
    fetch(api.GetRegisterApiUrl(), requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Login
          window.localStorage.setItem('pg_token', data.token);
          window.location.href = '/success';
        } else {
          if (data.reason == 1) {
            this.showEmailTaken = true;
          } else {
            this.showError = true;
          }
          this.Register = false;
        }
      });
  }

  CallValidateRegisterAPI() {
    var api = new ApiService();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.Email,
        firstname: this.Firstname,
        lastname: this.Lastname,
        password: this.Password,
      }),
    };

    fetch(api.GetValidateRegisterApiUrl(), requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.ShowStripe = true;
          this.CallRegisterCheckoutSessionAPI();
        } else {
          if (data.reason == 1) {
            this.showEmailTaken = true;
          } else {
            this.showError = true;
          }
          this.Register = false;
        }
      });
  }

  private onInputChange(e: any) {
    if (e.target.id == 'password') {
      this.Password = e.target.value;
    } else if (e.target.id == 'firstname') {
      this.Firstname = e.target.value;
    } else if (e.target.id == 'lastname') {
      this.Lastname = e.target.value;
    } else if (e.target.id == 'email') {
      this.Email = e.target.value;
    }
  }

  private onSubmitRegistration() {
    const form = this.shadowRoot?.querySelector(
      '#regform'
    ) as HTMLFormElement | null;

    if (form) {
      form.reportValidity();

      if (form.checkValidity()) {
        if (!this.stripeSessionId) {
          this.CallValidateRegisterAPI();
        } else {
          this.CallRegisterAPI();
        }
      }
    }
  }

  render() {
    return html`
      <main>
        <div class="wrapper">
          <img
            src="/images/promoguy-logo.png"
            class="logo-mobile"
            alt="Promoguy Login"
          />
          <div class="box">
            <div class="promologin">
              <img
                src="/images/promoguy-logo.png"
                class="logo"
                alt="Promoguy Login"
              />
              <div class="headline">We do the math, you make the cash.</div>
              <p>
                Welcome to PromoGuy Plus! Your go-to app and community for
                smarter +EV Betting in the US. Sign up and get access to
                exclusive data-driven bets daily, as soon as we find them!
              </p>
              <p class="bold">
                We have tracked on average 30% ROI on our+EV bets in the past 12
                months.
              </p>
              <p>
                Whether you’re a beginner or a pro, get real-time +EV bets
                straight to your phone and desktop!
              </p>

              <img
                class="focus"
                src="/images/promoguy-details.svg"
                alt="Details"
              />
            </div>
            <div class="login">
              <div class="headline">Create your account</div>
              <div class="form">
                ${!this.ShowStripe
                  ? html`<form id="regform" name="regform">
                      <input
                        @input=${(e: any) => this.onInputChange(e)}
                        type="text"
                        placeholder="First Name"
                        id="firstname"
                        required
                      />
                      <input
                        @input=${(e: any) => this.onInputChange(e)}
                        type="text"
                        placeholder="Last Name"
                        id="lastname"
                        required
                      />
                      <input
                        @input=${(e: any) => this.onInputChange(e)}
                        type="text"
                        placeholder="E-Mail"
                        id="email"
                        required
                      />
                      <input
                        @input=${(e: any) => this.onInputChange(e)}
                        type="password"
                        placeholder="Password"
                        id="password"
                        required
                      />
                      <p>
                        By Signing Up, you agree to all Terms & Privacy Policy.
                      </p>
                      <div
                        class="btn ${this.Register ? 'btn-disabled' : ''}"
                        @click=${this.Register
                          ? () => {}
                          : this.onSubmitRegistration}
                      >
                        ${this.Register ? Spinner.renderSpinner() : 'SIGN UP'}
                      </div>
                      ${this.showError
                        ? html`<p class="error">
                            An error occurred. Please try again in a few
                            minutes.
                          </p>`
                        : ''}
                      ${this.showEmailTaken
                        ? html`<p class="error">
                            This email is already registered. Already have an
                            account? <a href="/">Sign In</a>
                          </p>`
                        : ''}
                    </form>`
                  : html`<div class="stripe">
                      <div id="checkout"></div>
                    </div>`}
                ${!this.ShowStripe
                  ? html`<div>
                      <p class="text-light center">or continue with</p>
                      <div
                        class="social-buttons"
                        @click=${() => {
                          window.location.href = '/auth/registerdiscord';
                        }}
                      >
                        <div class="social-btn discord">Discord</div>
                      </div>
                    </div>`
                  : ''}

                <p class="text-light center">
                  Already have an account? <a href="/">Sign In</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    `;
  }
}
