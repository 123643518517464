// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import('urlpattern-polyfill');
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/register.js';
import './pages/login.js';

const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
  routes: [
    {
      path: resolveRouterPath(),
      title: 'Login',
      render: () => html`<login-app></login-app>`,
    },
    {
      path: resolveRouterPath('register'),
      title: 'Register',
      render: () => html`<register-app></register-app>`,
    },
    {
      path: resolveRouterPath('learn'),
      title: 'Learn',
      plugins: [lazy(() => import('./pages/learn.js'))],
      render: () => html`<learn-app></learn-app>`,
    },
    {
      path: resolveRouterPath('learn-read'),
      title: 'Learn',
      plugins: [lazy(() => import('./pages/learn-read.js'))],
      render: () => html`<learnread-app></learnread-app>`,
    },
    {
      path: resolveRouterPath('overview'),
      title: '+EV Bets',
      plugins: [lazy(() => import('./pages/overview.js'))],
      render: () => html`<overview-app></overview-app>`,
    },
    {
      path: resolveRouterPath('contribute'),
      title: 'Contribute',
      plugins: [lazy(() => import('./pages/contribute.js'))],
      render: () => html`<contribute-app></contribute-app>`,
    },
    {
      path: resolveRouterPath('contribute-add/:id?'),
      title: 'Contribute - Post a bet',
      plugins: [lazy(() => import('./pages/contribute-add.js'))],
      render: (params) =>
        html`<contributeadd-app
          editid="${params.params.id}"
        ></contributeadd-app>`,
    },
    {
      path: resolveRouterPath('account'),
      title: 'Account',
      plugins: [lazy(() => import('./pages/account.js'))],
      render: () => html`<account-app></account-app>`,
    },
    {
      path: resolveRouterPath('subscription'),
      title: 'Subscription',
      plugins: [lazy(() => import('./pages/subscription.js'))],
      render: () => html`<subscription-app></subscription-app>`,
    },
    {
      path: resolveRouterPath('success'),
      title: 'success',
      plugins: [lazy(() => import('./pages/success.js'))],
      render: () => html`<success-app></success-app>`,
    },
  ],
});

// This function will resolve a path with whatever Base URL was passed to the vite build process.
// Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
// If no arg is passed to this function, it will return the base URL.

export function resolveRouterPath(unresolvedPath?: string) {
  var resolvedPath = baseURL;
  if (unresolvedPath) {
    resolvedPath = resolvedPath + unresolvedPath;
  }

  return resolvedPath;
}
