export default class DataStorageService {
    public static clearRegisterData() {
        localStorage.removeItem('registerData');
    }

    public static saveRegisterData(data: any) {
        localStorage.setItem('registerData', JSON.stringify(data));
    }

    public static getRegisterData() {
        return JSON.parse(localStorage.getItem('registerData') || '{}');
    }
}